<div class="container">
    <vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs"
                                *ngIf="product"
                                class="mb-2"
                                [linkLast]="true"></vsf-collection-breadcrumbs>
    <ng-template [ngIf]="product" [ngIfElse]="placeholder">
        <div class="row">
            <div class="col-12 col-md-6 text-center">
                <vsf-asset-gallery [assets]="[product.assets[0]]"
                                   [selectedAssetId]="assetOnFocus"></vsf-asset-gallery>
            
            
            <h3>Apercu</h3>
                
            <img [src]="previewImgUrl" alt="Painting Variant Previous">
                <!-- <vsf-asset-gallery [assets]=""
                [selectedAssetId]="assetOnFocus"></vsf-asset-gallery> -->
            </div>

            <div class="col-12 col-md-6" #zoomPreviewArea>
                <h2 class="display-4 product-name">{{ product.name }}</h2>
                <div class="purchase-options">
                    <div class="variant-selector" *ngIf="product.variants.length > 1">
                        <select [(ngModel)]="selectedVariant" (change)="onVariantSelectedChange($event)" class="form-control">
                            <option *ngFor="let variant of sortedVariants"
                                    [ngValue]="variant">{{ variant.name }}</option>
                        </select>
                    </div>
                    <div class="add-to-cart-row">
                        <div class="sku" *ngIf="isPrintVariant">
                            Cadre<br/> non-inclus
                        </div>
                        <div class="sku" *ngIf="!isPrintVariant">
                            Canvas<br/> Complet
                        </div>
                        <div class="price">
                            {{ selectedVariant?.priceWithTax | formatPrice }}
                        </div>
                        <div class="qty mr-md-2 ml-2">
                            <input type="number" min="0" max="99" placeholder="Qty" [(ngModel)]="qty"
                                   class="form-control form-control-lg">
                        </div>
                        <!-- <button [disabled]="qty === 0"
                                class="btn btn-primary btn-lg"
                                (click)="addToCart(selectedVariant, qty)">Add to cart
                        </button> -->
                        <button [disabled]="qty === 0"
                                class="btn btn-primary btn-lg"
                                (click)="addToCart(selectedVariant, qty)">Ajouter au panier
                        </button>
                    </div>
                </div>
                <div class="description" [innerHTML]="product.description">
                </div>

                <!-- <h3>More Information</h3> -->
                <div *ngIf="product.customFields.Artist != '' && product.customFields.Year && product.customFields.Location">
                    <h3>Plus d'information</h3>

                    <table >
                        <tr>
                        <th class="column-title">Artiste</th>
                        <th class="column-value">{{product.customFields.Artist}}</th>
                        </tr>

                        <tr>
                            <th class="column-title">Année</th>
                            <th class="column-value">{{product.customFields.Year}}</th>
                        </tr>

                        <tr>
                            <th class="column-title">Lieu</th>
                            <th class="column-value">{{product.customFields.Location}}</th>
                        </tr>

                        <!-- <tr>
                            <th class="column-title">Height</th>
                            <th class="column-value">{{product.customFields.Height}} cm</th>
                        </tr>

                        <tr>
                            <th class="column-title">Width</th>
                            <th class="column-value">{{product.customFields.Width}} cm</th>
                        </tr> -->

                    </table>
                </div>
               






            </div>
        </div>

          
    </ng-template>
    <ng-template #placeholder>
        <div class="placeholder-breadcrumb loading-placeholder"></div>
        <div class="row">
            <div class="col-12 col-md-6 text-center">
                <div class="product-image">
                    <div class="placeholder-image loading-placeholder"></div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="placeholder-title loading-placeholder"></div>
                <div class="placeholder-description loading-placeholder"></div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #addedToCartTemplate let-variant="variant" let-quantity="quantity" let-close="closeFn">
    <div class="add-to-cart-notification">
        <div class="thumb mr-2">
            <img
                class="rounded"
                [src]="(variant.featuredAsset?.preview || product.featuredAsset?.preview) + '?preset=tiny'"
                alt="product thumbnail"
            />
        </div>
        <div>{{ quantity }} x {{ variant.name }}</div>
    </div>
    <button class="btn btn-sm btn-outline-primary btn-block rounded" (click)="viewCartFromNotification(close)">
        Voir le panier
    </button>
</ng-template>
