import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { GetCollection } from '../../../common/generated-types';
// import { AssetPreviewPipe } from '../../pipes/asset-preview.pipe';

@Component({
    selector: 'return-policy-card',
    templateUrl: './return-policy.component.html',
    styleUrls: ['./return-policy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// export class PrivacyNoticeComponent implements OnChanges {
export class ReturnPolicyComponent {
    @Input() collection: GetCollection.Children;
    backgroundImage: SafeStyle;

    constructor(private sanitizer: DomSanitizer) {}

    // ngOnChanges() {
    //     if (this.collection.featuredAsset) {
    //         const assetPreviewPipe = new AssetPreviewPipe();
    //         this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
    //             `url('${assetPreviewPipe.transform(this.collection.featuredAsset, 400, 150)}')`,
    //         );
    //     }
    // }

}
