import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTiktok,
    faWhatsapp,
    // faTwitter,
    // faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faCreditCard,
    faFilter,
    faMinus,
    faPlus,
    faSadCry,
    faSearch,
    faShoppingCart,
    faTimes,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

/**
 * All the icons used in the library are declared here. This allows us to
 * ship only the needed icons so that the bundle does not get bloated.
 */
export function buildIconLibrary(library: FaIconLibrary) {
    library.addIcons(
        // faTwitter,
        faFacebook as IconDefinition,
        faInstagram as IconDefinition,
        faTiktok as IconDefinition,
        faWhatsapp as IconDefinition,
        // faYoutube,
        faTimes as IconDefinition,
        faMinus as IconDefinition,
        faPlus as IconDefinition,
        faShoppingCart as IconDefinition,
        faUserCircle as IconDefinition,
        faCreditCard as IconDefinition,
        faSearch as IconDefinition,
        faSadCry as IconDefinition,
        faFilter as IconDefinition,
        faBars as IconDefinition,
    );
}
