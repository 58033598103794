<form #signInForm="ngForm"
      (submit)="signIn()">
      <!-- <script src="https://apis.google.com/js/platform.js" async defer></script>
      <meta name="google-signin-client_id" content="820391823683-upf4ovejs1c56skijah7ika9429cqcrp.apps.googleusercontent.com"> -->
    <vsf-centered-card>

      
      <div class="googleSignInRef">

      </div>

    <h2>Se connecter</h2>

    
            <input class="form-control"
                   placeholder="Addresse Email"
                   name="emailAddress"
                   type="email"
                   email
                   required
                   [(ngModel)]="emailAddress">
            <input class="form-control mt-1"
                   placeholder="Mot de passe"
                   type="password"
                   name="password"
                   required
                   [(ngModel)]="password">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="rememberMe" name="rememberMe" [(ngModel)]="rememberMe">
          <label class="form-check-label" for="rememberMe">
            Se souvenir de ce compte
          </label>
        </div>

        <re-captcha (resolved)="resolved($event)" siteKey="6LfmUMohAAAAAJX6xEB81OugU0JIzYrKto3TzgcG"></re-captcha>


        <button class="btn btn-primary btn-block mt-3"
                [disabled]="signInForm.invalid || signInForm.pristine || captcha == ''"
                type="submit">
              Se connecter
        </button>

        
        
    <!-- <hr class="rounded">
    <a class="g-signin2" data-onsuccess="onSignInP" data-onfailure="onFailure" data-width="250" data-height="50" data-longtitle="true"></a> -->



        <div class="alert alert-warning invalid-credentials" role="alert"  [class.visible]="invalidCredentials">
          Les informations entrées sont invalides. Veuillez réessayer.<br>
        </div>
        <a [routerLink]="['/account/forgotten-password', { email: this.emailAddress }]">J'ai oublié mon mot de passe</a>

        <div *ngIf="displayRegisterLink" class="register-link">
            <a [routerLink]="['/account/register']">Pas de compte? Inscrivez-vous ici</a>
        </div>
    </vsf-centered-card>
</form>
