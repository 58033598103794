import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

// import { RecaptchaModule } from 'ng-recaptcha';

@Component({
  selector: 'vsf-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopUpComponent implements OnInit {
  // captcha: string;

  constructor() {
    // this.captcha = '';
   }

  ngOnInit(): void {
  }

  // resolved(captchaResponse: string) {
  //   this.captcha = captchaResponse;
  // }
  

}
