<vsf-layout>
    <vsf-layout-header>
        <div class="header">
            <div class="container">
                <vsf-mobile-menu-toggle></vsf-mobile-menu-toggle>
                <a
                    [routerLink]="['/']"
                    class="logo-link d-none d-md-inline-block"
                >
                    <img
                        src="assets/images/artologyTxt.svg"
                        class="logo"
                        alt="Artology Storefront"
                    />
                </a>
                <div class="flex-grow-1 d-none d-sm-block"></div>
                <vsf-product-search-bar
                    class="flex-grow-1"
                ></vsf-product-search-bar>
                <vsf-account-link class="mr-2"></vsf-account-link>
                <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
            </div>
            <vsf-collections-menu class="mt-4"></vsf-collections-menu>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile
        [visible]="mobileNavVisible$ | async"
    ></vsf-collections-menu-mobile>
    <vsf-cart-drawer
        [visible]="cartDrawerVisible$ | async"
        (close)="closeCartDrawer()"
    ></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <div class="row text-muted mt-5 mb-5">
            <!-- <div class="col-md-3 text-center text-md-left">
                <h3>Orders</h3>
                <ul class="list-unstyled">
                    <li>Shipping</li>
                    <li>Track My Order</li>
                    <li>Terms & Conditions</li>
                    <li>Returns Policy</li>
                </ul>
            </div> -->
            <div class="col-md-3 text-center text-md-left">
                <h3>Documents</h3>
                <ul class="list-unstyled">
                    <!-- <li><a [routerLink]="['/about-us']" class="product-name">About Us</a></li> -->
                    <li>
                        <a
                            [routerLink]="['/privacy-notice']"
                            class="product-name"
                            >Avis de Confidentialité</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/terms-conditions']"
                            class="product-name"
                            >Termes &amp; Conditions</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/return-policy']"
                            class="product-name"
                            >Politique de retour</a
                        >
                    </li>
                </ul>
            </div>
            <div class="col">
                <div class="social d-flex justify-content-around">
                    <a href="contact.thamza.com"
                        ><fa-icon
                            size="2x"
                            [icon]="['fab', 'facebook']"
                        ></fa-icon
                    ></a>
                    <a href="contact.thamza.com"
                        ><fa-icon
                            size="2x"
                            [icon]="['fab', 'instagram']"
                        ></fa-icon
                    ></a>
                    <a href="contact.thamza.com"
                        ><fa-icon size="2x" [icon]="['fab', 'tiktok']"></fa-icon
                    ></a>
                    <a href="contact.thamza.com"
                        ><fa-icon
                            size="2x"
                            [icon]="['fab', 'whatsapp']"
                        ></fa-icon
                    ></a>
                </div>
                <div class="mailist mt-5 d-flex justify-content-around">
                    <div class="lead">
                        Abonnez-vous à notre liste de diffusion pour des
                        nouvelles et des offres exclusives!
                    </div>
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-lg"
                        (click)="openMailchimpSubscriptionDialog()"
                    >
                        Abonnez-vous
                    </button>
                </div>
            </div>
        </div>
        <div class="text-center text-secondary bottom-text">
            <a href="mailto:contact@artology.ma">Contactez-nous</a> pour plus
            d'informations. <br />
            Built with <a href="https://ecommerce.thamza.com/">❤️</a>
        </div>
    </vsf-layout-footer>
</vsf-layout>
