import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SignIn } from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';

import { SIGN_IN } from './sign-in.graphql';

// import {
//     SocialLoginModule,
//     SocialAuthServiceConfig,
//   } from 'angularx-social-login';
import {gql} from 'apollo-angular';


declare var gapi: any;

@Component({
    selector: 'vsf-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {
    @Input() navigateToOnSuccess: any[] | undefined;
    @Input() displayRegisterLink = true;
    // @ViewChild("@ViewChild('googleSignInButtonToAdd') d1:ElementRef;") d1:ElementRef;

    emailAddress: string;
    password: string;
    rememberMe = false;
    invalidCredentials = false;
    captcha: string;

    constructor(private dataService: DataService,
                private stateService: StateService,
                private router: Router,
                private changeDetector: ChangeDetectorRef,
                private googleSignInRef:ElementRef) {
                    
                    this.captcha = '';
    }

    
//     ngOnInit() {
//         let d1 = this.googleSignInRef.nativeElement.querySelector('.googleSignInRef');
//         console.log(d1);
//         d1.insertAdjacentHTML('beforeend', '<div class="g-signin2" data-onsuccess="onSignIn" data-width="250" data-height="50" data-longtitle="true"></div>');
//   }

    resolved(captchaResponse: string) {
        this.captcha = captchaResponse;
      }

    signIn() {
        this.dataService.mutate<SignIn.Mutation, SignIn.Variables>(SIGN_IN, {
            emailAddress: this.emailAddress,
            password: this.password,
            rememberMe: this.rememberMe,
        }).subscribe({
            next: ({login}) => {
                switch (login.__typename) {
                    case 'CurrentUser':
                        this.stateService.setState('signedIn', true);
                        const commands = this.navigateToOnSuccess || ['/'];
                        this.router.navigate(commands);
                        break;
                    case 'NativeAuthStrategyError':
                    case 'InvalidCredentialsError':
                        this.displayCredentialsError();
                        break;
                }
            },
        });
    }

    // init() {
    //     gapi.load('auth2', function() {
    //       /* Ready. Make a call to gapi.auth2.init or some other API */
    //     });
    //   }

    
      
    onFailure() { console.error('Sign in has failed!'); }
    onSignIn(googleUser: any) {
        console.log("HERE");
        Promise.resolve(googleUser).then((user) => {
            return gql(
                `mutation Authenticate($token: String!) {
                    authenticate(input: {
                    google: { token: $token }
                    }) {
                    ...on CurrentUser {
                        id
                        identifier
                    }
                }
                }`,
                { token: googleUser.getAuthResponse().id_token }
          )
        }).then((data) => {
            console.log(data);
        })
      }
      

    private displayCredentialsError() {
        this.invalidCredentials = false;
        this.changeDetector.markForCheck();
        setTimeout(() => {
            this.invalidCredentials = true;
            this.changeDetector.markForCheck();
        }, 50);
    }
}
