<div class="product-card">
    <ng-template [ngIf]="product" [ngIfElse]="placeholder">
        <a [routerLink]="['/product', product.slug]" class="image-wrapper">
            <img class="product-image rounded" [src]="product.productAsset | assetPreview:200:200">
        </a>
        <div class="">
            <a [routerLink]="['/product', product.slug]" class="product-name">
                {{ product.productName }}
            </a>
            <div class="price" >
                
                <div class="" #showprice>
                        {{ product.priceWithTax.min | formatPrice }}

                    <!-- <ng-template [ngIf]="product.priceWithTax.min === product.priceWithTax.max && product.priceWithTax.min !== 0" [ngIfElse]="range" #notZero>
                        {{ product.priceWithTax.min | formatPrice }}
                    </ng-template>
                    <ng-template [ngIf]="product.priceWithTax.min !== product.priceWithTax.max && product.priceWithTax.min !== 0" #range>
                        <div [ngIf]="product.priceWithTax.min !== 0">
                             From {{ product.priceWithTax.min | formatPrice }}
                        </div>
                    </ng-template> -->
                </div>
                
            </div>
        </div>
    </ng-template>
    <ng-template #placeholder>
        <div class="placeholder-image loading-placeholder"></div>
        <div class="card-body">
            <div class="placeholder-title loading-placeholder"></div>
            <div class="placeholder-body loading-placeholder"></div>
        </div>
    </ng-template>
</div>
