<div class="hero" [style.background-image]="heroImage">
    <!-- <h6 class="display-1 title text-center">artology</h6>  -->
    <!-- <img class="main-text" src="assets/images/artologyTxt.svg" > -->
    <!-- <br/> -->
</div>

<div class="container">
    <div class="top-categories mt-5">
        <vsf-collection-card
            *ngFor="let collection of collections$ | async"
            [collection]="collection"
        >
        </vsf-collection-card>
    </div>
    <!-- <h3 class="display-4 mt-5">Best Seller</h3> -->
    <h3 class="display-4 mt-5">Best-Seller</h3>
    <div class="product-list">
        <ng-container *ngIf="topSellersLoaded$ | async; else placeholders">
            <vsf-product-card
                *ngFor="let product of topSellers$ | async"
                [product]="product"
            ></vsf-product-card>
        </ng-container>
        <ng-template #placeholders>
            <vsf-product-card
                *ngFor="let product of placeholderProducts"
                [product]="product"
            ></vsf-product-card>
        </ng-template>
    </div>
</div>
