import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StateService } from './core/providers/state/state.service';

import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from './pop-up/pop-up.component';

declare var gtag: (arg0: string, arg1: string, arg2: { page_path: string; }) => void;

@Component({
    selector: 'sf-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    cartDrawerVisible$: Observable<boolean>;
    mobileNavVisible$: Observable<boolean>;
    isHomePage$: Observable<boolean>;

    constructor(private router: Router,
                private stateService: StateService,
                private dialogRef: MatDialog) {
                    const navEndEvents = router.events.pipe(
                        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
                    );
                    navEndEvents.subscribe((event: NavigationEnd) => {
                        gtag('config', 'G-0ZKNNM1SRK', {
                            'page_path': event.urlAfterRedirects
                        }); 
                    });
    }

    ngOnInit(): void {
        this.cartDrawerVisible$ = this.stateService.select(state => state.cartDrawerOpen);
        this.mobileNavVisible$ = this.stateService.select(state => state.mobileNavMenuIsOpen);
        this.isHomePage$ = this.router.events.pipe(
            filter<any>(event => event instanceof RouterEvent),
            map((event: RouterEvent) => event.url === '/'),
        );
    }

    openCartDrawer() {
        this.stateService.setState('cartDrawerOpen', true);
    }

    closeCartDrawer() {
        this.stateService.setState('cartDrawerOpen', false);
    }

    openMailchimpSubscriptionDialog() {
        this.dialogRef.open(PopUpComponent)
    }
        
}
