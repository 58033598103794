<div class="masthead" [class.search-mode]="searchTerm$ | async">
    <div class="masthead-bg" [style.background-image]="mastheadBackground$ | async"></div>
    <div class="container position-relative">
        <vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></vsf-collection-breadcrumbs>
        <h1 class="display-1 collection-name">{{ (collection$ | async)?.name }}</h1>
        <h1 class="display-4 text-muted" *ngIf="searchTerm$ | async as term">
             Results for <span class="text-dark">"{{ term }}"</span>
        </h1>
    </div>

</div>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-2">
            <vsf-product-list-controls [facetValues]="facetValues"
                                       [activeFacetValueIds]="activeFacetValueIds$ | async"
                                       [totalResults]="unfilteredTotalItems"></vsf-product-list-controls>
        </div>
        <div class="col-12 col-md-10">
            <ng-container *ngIf="collection$ | async as collection">
                <div *ngIf="collection.children.length" class="child-categories d-flex flex-column flex-md-row">
                    <vsf-collection-card *ngFor="let child of collection.children"
                                         [collection]="child">
                    </vsf-collection-card>
                </div>
            </ng-container>
            <ng-container *ngIf="(totalResults$ | async) !== 0; else noResults">
                <div class="product-list">
                    <ng-container *ngIf="(totalResults$ | async) !== null; else placeholders">
                        <vsf-product-card *ngFor="let product of products$ | async; trackBy: trackByProductId"
                                          [product]="product"></vsf-product-card>
                    </ng-container>
                    <ng-template #placeholders>
                        <vsf-product-card *ngFor="let product of placeholderProducts"
                                          [product]="product"></vsf-product-card>
                    </ng-template>
                    <div class="load-more flex-fill" *ngIf="displayLoadMore$ | async">
                        <button class="btn btn-light btn-lg d-inline-flex align-items-center"
                                (click)="loadMore()"
                                [disabled]="loading$ | async">
                            Plus
                            <span [class.show]="loading$ | async"
                                  class="loading-indicator spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noResults>
    <div class="no-results col-12">
        <p class="h1">No results</p>
        <fa-icon icon="sad-cry" size="10x"></fa-icon>
    </div>
</ng-template>
