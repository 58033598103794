import { escapeRegExp } from '@angular/compiler/src/util';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
//import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { threadId } from 'worker_threads';

import { AddToCart, GetProductDetail } from '../../../common/generated-types';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../../providers/data/data.service';
import { NotificationService } from '../../providers/notification/notification.service';
import { StateService } from '../../providers/state/state.service';

import { ADD_TO_CART, GET_PRODUCT_DETAIL } from './product-detail.graphql';

declare global {
    interface Window { dataLayer: any[]; }
}

@Component({
    selector: 'vsf-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnDestroy {

    previewImgUrl : string;
    isPrintVariant: Boolean;
    assetOnFocus: any;
    sortedVariants: GetProductDetail.Variants[];
    product: GetProductDetail.Product;
    selectedAsset: { id: string; preview: string; };
    selectedVariant: GetProductDetail.Variants;
    qty = 1;
    breadcrumbs: GetProductDetail.Breadcrumbs[] | null = null;
    @ViewChild('addedToCartTemplate', {static: true})
    private addToCartTemplate: TemplateRef<any>;
    private sub: Subscription;
    

    constructor(private dataService: DataService,
                private stateService: StateService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private router: Router
//                private browserLocation: Location
		) {
            window.dataLayer = window.dataLayer || [];
            this.isPrintVariant = true;
    }

    ngOnInit() {
        const lastCollectionSlug$ = this.stateService.select(state => state.lastCollectionSlug);
        const productSlug$ = this.route.paramMap.pipe(
            map(paramMap => paramMap.get('slug')),
            filter(notNullOrUndefined),
        );

        this.sub = productSlug$.pipe(
            switchMap(slug => {
                return this.dataService.query<GetProductDetail.Query, GetProductDetail.Variables>(GET_PRODUCT_DETAIL, {
                        slug,
                    },
                );
            }),
            map(data => data.product),
            filter(notNullOrUndefined),
            withLatestFrom(lastCollectionSlug$),
        ).subscribe(([product, lastCollectionSlug]) => {
            this.product = product;
            
            // traverse this.product.variants. each variant has a name formated like this: "61cm x 48cm print" or "61cm x 48cm canvas". This piece of code sorts the variants by first number ascendant and if two matches, sort by the last word ("print" or "canvas") descandant. I tried to use the sort function but I can't figure out how to do it. Any help would be appreciated.
            this.sortedVariants = this.product.variants.slice().sort(function(a, b) {
                var nameWordsa = a.name.split(' ');
                var nameWordsb = b.name.split(' '); 
                var lena = nameWordsa.length;
                var lenb = nameWordsb.length;
                var a1 = Number(nameWordsa[0].replace('cm', ''));
                var b1 = Number(nameWordsb[0].replace('cm', ''));
                var a2 = nameWordsa[lena - 1];
                var b2 = nameWordsb[lenb - 1];

                //sort by a2 and b2 alphabetically descandant if a1 and b1 are equal, else sort by a1 and b1 numerically ascendant
                
                if (a2 < b2) return 1;
                if (a2 > b2) return -1;
                
                if (a1 < b1) return -1;
                if (a1 > b1) return 1;
                return 0;
                // if (a1 == b1) {
                //     if (a2 < b2) return 1;
                //     if (a2 > b2) return -1;
                //     return 0;
                // }
                // else {
                //     if (a2 < b2) return 1;
                //     if (a2 > b2) return -1;
                //     return 1;
                // }
                
            });


            this.selectedVariant = product.variants[product.variants.length - 1];
            this.isPrintVariant = this.selectedVariant.name.includes('print');
            if (this.product.featuredAsset) {
                this.selectedAsset = this.product.featuredAsset;
                this.assetOnFocus = this.product.featuredAsset?.id;
                // this.assetOnFocus = this.product.assets[1]?.id;
                //this.previewImgUrl = this.product.featuredAsset?.preview

                let selectedAsset = this.product.assets.find(asset => asset.name.replaceAll("-", "").includes(this.selectedVariant.name.replaceAll('cm', '').replaceAll('x', '').replaceAll(' ', '').toLowerCase()));
                console.log(selectedAsset);
                this.previewImgUrl = selectedAsset?.preview ||'';

                // this.previewImgUrl = this.product.assets[1].preview;

            }


            const collection = this.getMostRelevantCollection(product.collections, lastCollectionSlug);
            this.breadcrumbs = collection ? collection.breadcrumbs : [];
        });

             
        window.dataLayer.push({
            "ecommerce": {
                "currencyCode": "MAD",
                "detail": {
                    "products": [
                        {
                            "id": this.product?.id,
                            "name" : this.product?.name,
                            "price": this.selectedVariant?.price,
                            "category": this.product?.collections[0]?.slug,
                            "variant" : this.selectedVariant?.name
                        }
                    ]
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    addToCart(variant: GetProductDetail.Variants, qty: number) {
        this.dataService.mutate<AddToCart.Mutation, AddToCart.Variables>(ADD_TO_CART, {
            variantId: variant.id,
            qty,
        }).subscribe(({addItemToOrder}) => {
            switch (addItemToOrder.__typename) {
                case 'Order':
                    // console.log(addItemToOrder.id);
                    this.stateService.setState('activeOrderId', addItemToOrder ? addItemToOrder.id : null);
                    //print the value of activeOrderId
                    // console.log(this.stateService.select(state => state));
                    
                    if (variant) {
                        this.notificationService.notify({
                            title: 'Added to cart',
                            type: 'info',
                            duration: 3000,
                            templateRef: this.addToCartTemplate,
                            templateContext: {
                                variant,
                                quantity: qty,
                            },
                        }).subscribe();
                        
                     //   console.log("HERE");
                    //  //   this.browserLocation.back();
                    }
                    break;
                case 'OrderModificationError':
                case 'OrderLimitError':
                case 'NegativeQuantityError':
                case 'InsufficientStockError':
                    this.notificationService.error(addItemToOrder.message).subscribe();
                    // console.log(addItemToOrder.message);
                    break;
            }

        });

        window.dataLayer.push({
            "ecommerce": {
                "currencyCode": "MAD",    
                "add": {
                    "products": [
                        {
                            "id": this.product?.id,
                            "name": this.product?.name,
                            "price": this.selectedVariant?.price,
                            "category": this.product.collections[0]?.slug,
                            "quantity": qty
                        }
                    ]
                }
            }
        });
    }

    viewCartFromNotification(closeFn: () => void) {
        this.stateService.setState('cartDrawerOpen', true);
        closeFn();
    }

    /**
     * If there is a collection matching the `lastCollectionId`, return that. Otherwise return the collection
     * with the longest `breadcrumbs` array, which corresponds to the most specific collection.
     */
    private getMostRelevantCollection(collections: GetProductDetail.Collections[], lastCollectionSlug: string | null) {
        const lastCollection = collections.find(c => c.slug === lastCollectionSlug);
        if (lastCollection) {
            return lastCollection;
        }
        return collections.slice().sort((a, b) => {
            if (a.breadcrumbs.length < b.breadcrumbs.length) {
                return 1;
            }
            if (a.breadcrumbs.length > b.breadcrumbs.length) {
                return -1;
            }
            return 0;
        })[0];
    }

    // replaceAll(str: string, find: any, replace: any) {
    //     return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    // }

    //When the user changes the variant using the <select> tag, this function is called. It updates the selectedVariant variable and the dataLayer and the selectedAsset variable to match the selected variant.
    onVariantSelectedChange(variantOption: any) {
        // this.selectedVariant = variant;
        //get the selected option name
        

        let selectedOption = variantOption.target.options[variantOption.target.options.selectedIndex].text;
        this.isPrintVariant = selectedOption.includes('print');
        // //traverse this.product.assets to find the asset wich has widtheight-print or widtheight-canvas. for example: asset name = "the-bedroom-4132-print.jpg" and selectedVariant.name = "41cm x 32cm print"
        // console.log(selectedOption.replaceAll('cm', '').replaceAll('x', '').replaceAll(' ', '').toLowerCase());
        // console.log(this.assetOnFocus);
        //let selectedAsset = this.product.assets.find(asset => asset.name.replaceAll("-", "").includes(selectedOption.replaceAll('cm', '').replaceAll('x', '').replaceAll(' ', '').toLowerCase()));
        // traverse this.product.assets to find the asset wich has widtheight-print or widtheight-canvas. for example: asset name = "the-bedroom-4132-print.jpg" and selectedVariant.name = "41cm x 32cm print"
        let selectedAsset = this.product.assets.find(asset => asset.name.replaceAll("-", "").replaceAll("print", "").replaceAll("canvas", "").includes(this.selectedVariant.name.replaceAll('cm', '').replaceAll('x', '').replaceAll(' ', '').replaceAll("print", "").replaceAll("canvas", "").toLowerCase()));
        
        console.log(selectedAsset);
        console.log(this.product.assets);
        console.log(selectedOption.replaceAll('cm', '').replaceAll('x', '').replaceAll(' ', '').toLowerCase());
        this.previewImgUrl = selectedAsset?.preview ||'';
        // set this.previewImgUrl to this.product.assets of index variantOption.target.options.selectedIndex .preview
        // this.previewImgUrl = this.product.assets[variantOption.target.options.selectedIndex - 1].preview;

        this.assetOnFocus = selectedAsset?.id;
        // console.log(this.assetOnFocus);selectedAsset

        

        
        // this.selectedAsset = variant.featuredAsset;
        // window.dataLayer.push({
        //     "ecommerce": {
        //         "currencyCode": "MAD",
        //         "detail": {
        //             "products": [
        //                 {
        //                     "id": this.product?.id,
        //                     "name" : this.product?.name,
        //                     "price": this.selectedVariant?.price,
        //                     "category": this.product?.collections[0]?.slug,
        //                     "variant" : this.selectedVariant?.name
        //                 }
        //             ]
        //         }
        //     }
        // });
        
    }

}
