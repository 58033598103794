import { Route } from '@angular/router';

import { ProductDetailComponent } from './core/components/product-detail/product-detail.component';
import { ProductListComponent } from './core/components/product-list/product-list.component';

import { PrivacyNoticeComponent } from './shared/components/privacy-notice/privacy-notice.component';
import { TermsAndConditionsComponent } from './shared/components/terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './shared/components/about-us/about-us.component';
import { ReturnPolicyComponent } from './shared/components/return-policy/return-policy.component';

// import { AccountGuard } from './account/providers/account.guard';

export const routes: Route[] = [
    {
        path: 'category/:slug',
        // canActivate: [AccountGuard],
        component: ProductListComponent,
        pathMatch: 'full',
    },
    {
        path: 'search',
        // canActivate: [AccountGuard],
        component: ProductListComponent,
    },
    {
        path: 'product/:slug',
        // canActivate: [AccountGuard],
        component: ProductDetailComponent,
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'privacy-notice',
        // canActivate: [AccountGuard],
        component: PrivacyNoticeComponent,
    },
    {
        path: 'terms-conditions',
        // canActivate: [AccountGuard],
        component: TermsAndConditionsComponent,
    },
    {
        path: 'about-us',
        // canActivate: [AccountGuard],
        component: AboutUsComponent,
    },
    {
        path: 'return-policy',
        // canActivate: [AccountGuard],
        component: ReturnPolicyComponent,
    },
    {
        path: 'checkout',
        // canActivate: [AccountGuard],
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
    },
];
