<div *ngIf="activeCustomer$ | async as customer; else notLoggedIn">
    <a class="btn btn-light text-muted" [routerLink]="['/account']">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-sm-inline ml-1">{{ userName(customer) }}</span>
    </a>
</div>
<ng-template #notLoggedIn>
    <a class="btn btn-light text-muted" [routerLink]="['account/sign-in']">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-sm-inline ml-1 ">Se connecter</span></a>
        <div class="content-component" [ngStyle]="{'width': width + 'px', 'height': height +'px' }">
            <div #overlay>
                
            </div>
        </div>
</ng-template>
