<div class="cart-drawer-header">
    <button class="btn" (click)="close.emit()">
        <fa-icon icon="times"></fa-icon>
    </button>
    <h3 class="cart-title">Panier</h3>
</div>
<div *ngIf="!(isEmpty$ | async); else empty" class="cart-drawer-contents">
    <vsf-cart-contents [cart]="cart$ | async"
                       [canAdjustQuantities]="true"
                       (setQuantity)="setQuantity($event)"></vsf-cart-contents>
    <div class="checkout-button">
        <a class="btn btn-block btn-primary"
           color="primary"
           (click)="close.emit()"
           [routerLink]="['/checkout']">Finaliser mes achats</a>
    </div>
</div>
<ng-template #empty>
    <div class="empty">
        Panier est vide
    </div>
</ng-template>
